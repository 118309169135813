.contact-info {
  background: #e8e8e8;
  border: 1px solid #222;
  padding: 20px;
  line-height: 1.5em;
}

.contact-form {
  background: #FFF;


  .upsells {
    font-size: 1.2em;
    svg {
      color: $primary-color;
    }
  }
  label {
    font-weight: bolder;
    font-size: 1em;
  }
  input, select, textarea {
    background: transparentize($primary-color, .8);
    box-shadow: none;
    border: 2px solid $primary-color;
    &:active, &:focus {
      background: #FFF;
      box-shadow: none;
      border: 2px solid $primary-color;
    }
  }

  select {
    margin-bottom: 0;
  }

  .button {
    width:100%;
    font-weight: bolder;
    font-size: 1.1em;
  }
}