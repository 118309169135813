.intro {
  background: $primary-color;
  background-image: url('/assets/img/introbg.jpg');
  background-size: cover;
  background-position: bottom;
  padding: 220px 0 20vh;

  &.short {
    padding: 180px 0 10vh;
  }

  h1,
  h2 {
    color: #FFF;
    font-size: 3.5em;
    line-height: 1.3em;
    animation: upin 1s ease-out 0s;
  }

  p {
    color: #FFF;
    animation: upin 1.5s ease-out 0s;
  }

  .button {
    animation: upin 2s ease-out 0s;
  }
}

.about {
  padding: 60px 0;
}

.staff {
  padding: 60px 0;

  .staff_member {
    display: block;
    text-align: center;
    margin-top: 50px;
    border: 3px solid #e8e8e8;
    transition: .5s all ease;

    h3 {
      margin-bottom: 0;
    }

    p {
      color: #444;
      font-weight: bolder;
    }

    img {
      transition: .5s all ease;
      margin-top: -50px;
    }

    &:hover {
      border-color: $primary-color;

      img {
        transform: translateY(-15px)
      }
    }
  }
}

.blog {
  padding: 50px 30px;
  background-color: $primary-color;
  background-image: url(/assets/img/bluestembg.jpg);
  background-position: bottom;

  h2 {
    color: #FFF;
  }

  .post {
    transition: .5s all ease;
    border: 2px solid #FFF;
    border-radius: 5px;
    padding: 20px 20px;
    height: 100%;

    .date {
      font-weight: bold;
      color: rgba(255, 255, 255, .6);
      font-size: 1.4em;
    }

    h3 {
      transition: .5s all ease;
      color: #FFF;
      text-decoration: underline;
    }

    p {
      color: #FFF;
    }

    &:hover {
      border-color: $secondary-color;
      transform: translateY(-10px);

      h3 {
        color: $secondary-color;
      }
    }
  }
}

@include breakpoint (medium down) {
  .intro {
    padding: 140px 0 20vh;

    h1,
    h2 {
      color: #FFF;
      font-size: 2.5em;
      line-height: 1.3em;
    }

  }
}

@keyframes upin {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  60% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}