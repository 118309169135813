.button {
  font-weight: bolder;
  font-size: 1.3em;
  border-radius: 5px;
}

p, ul {
  font-size: 1.4em;
  line-height: 1.8em;
}

h2, h3, h4, h5 {
  color: $primary-color;
}

.page-content {
  padding: 60px 0;
  min-height: 70vh;
}

.grid-x, .container {
  max-width: 1450px;
  margin: auto;
}

//Fonts

@font-face{
  font-family:"Agmena Regular";
  src:url("/assets/fonts/f4e44248-782b-419c-9131-bfbc57a82c45.eot?#iefix");
  src:url("/assets/fonts/f4e44248-782b-419c-9131-bfbc57a82c45.eot?#iefix") format("eot"),url("/assets/fonts/296593f8-9ed9-4c14-9f42-9070531fd89b.woff2") format("woff2"),url("/assets/fonts/4d7ce4ec-de63-40bf-8e81-0ad69d9b2cd9.woff") format("woff"),url("/assets/fonts/24d7b935-352e-49db-8076-59aefc75cedc.ttf") format("truetype");
}
@font-face{
  font-family:"Agmena Bold";
  src:url("/assets/fonts/60370258-a0cb-42d0-834d-dbc93c1054b2.eot?#iefix");
  src:url("/assets/fonts/60370258-a0cb-42d0-834d-dbc93c1054b2.eot?#iefix") format("eot"),url("/assets/fonts/a3ef2c3c-1b08-4f35-a0b4-8e55459efb41.woff2") format("woff2"),url("/assets/fonts/0b7f2fd4-2198-4c64-9a32-474f977dd42c.woff") format("woff"),url("/assets/fonts/412456fd-fbb6-48b9-8544-7200108467dc.ttf") format("truetype");
}
@font-face{
  font-family:"Radikal";
  src:url("/assets/fonts/5fc0c107-b2d4-4a0c-bdc5-158ec3e3a50e.eot?#iefix");
  src:url("/assets/fonts/5fc0c107-b2d4-4a0c-bdc5-158ec3e3a50e.eot?#iefix") format("eot"),url("/assets/fonts/237e7a51-7fe7-4228-879f-ee12be361e89.woff2") format("woff2"),url("/assets/fonts/c599e32d-4281-4f4b-915f-1785ffa4fcaa.woff") format("woff"),url("/assets/fonts/36296a6f-7cf1-42a4-8635-9fcd10dc0a7d.ttf") format("truetype");
}

//Semantic Styles

.float-left {
  float: left;
}

.float-right {
  float: right;
}

@mixin down-x ($x) {
  padding-top: $x;
}
@mixin spread-x ($x) {
  padding-right: $x;
  padding-left: $x;
}
@mixin bot-x ($x) {
  padding-bottom: $x;
}
@mixin pad-xy ($x, $y) {
  padding: $y $x;
}

.no-pad {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.pad-half {
  padding: 0.5em;
}

.pad-1 {
  padding: 1em;
}

.pad-2 {
  padding: 2em;
}

.pad-3 {
  padding: 3em;
}
/* Bottom*/
.bot-half {
  padding-bottom: 0.5em;
}

.bot-1 {
  padding-bottom: 1em;
}

.bot-2 {
  padding-bottom: 2em;
}

.bot-3 {
  padding-bottom: 3em;
}
/* Spread*/
.spread-1 {
  padding-right: 1em;
  padding-left: 1em;
}

.spread-2 {
  padding-right: 2em;
  padding-left: 2em;
}

.spread-3 {
  padding-right: 3em;
  padding-left: 3em;
}
/* Down*/
.down-1 {
  padding-top: 1em;
}

.down-2 {
  padding-top: 2em;
}

.down-3 {
  padding-top: 3em;
}

.down-half {
  padding-top: 0.5em;
}

//Angled Edges
@mixin angle($pseudo, $flip: false, $angle: 1.5deg) {
  // Possible values for $pseudo are: before, after, both
  @if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
    position: relative;
    z-index: 1;
    $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

    #{$selector} {
      background: inherit;
      content: '';
      display: block;
      height: 50%;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
      -webkit-backface-visibility: hidden; // for Chrome Windows
    }

    @if $pseudo == 'before' {
      #{$selector} {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }
    }

    @if $pseudo == 'after' {
      #{$selector} {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 100%;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }

    @if $pseudo == 'both' {
      &:before {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }

      &:after {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }
  }
}
