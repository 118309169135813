$foundation-palette: (
  primary: #28666E,
  secondary: #FEDC97,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
) !default;

$bg-color: #F7F6EE;

$footer-height: 90px;
$nav-height: 110px;

$header-font-family: "Radikal","Helvetica Neue", sans-serif;
$body-font-family: "Agmena Regular", "PT Serif", serif;
$paragraph-lineheight: 1.6;

$upper-nav-height: 35px;
$lower-nav-height: 120px;