.post-full {
  .date-author {
    font-size: 1.4em;
    font-family: $header-font-family;
    color: rgba(0,0,0,.6);
  }

  h2 {
    text-decoration: underline;
    transition: .5s ease;
      &:hover {
        color: darken($secondary-color, 20%);
    }
  }

  
}

.post-all {
  .date-author {
    font-family: $header-font-family;
    font-size: 1.3em;
    padding: 20px 0;
    display: block;
    color: rgba($color: #000000, $alpha: .6);
  }
  h1 {
    color: $primary-color;
  }
}

.about-the-author {
  padding-top: 20px;

  img {
    float: left;
    padding-right: 20px;
  }
}

@include breakpoint (medium down) {
  .post-all {
    .date-author {
      font-size: 1em;
    }
  }
}