.upper-nav {
  position: absolute;
  top: 0;
  background: darken($primary-color, 10%);
  width: 100%;
  z-index: 99;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    float: right;
    padding-right: 50px;
    li {
      display: inline-block;
      margin-right: 10px;
      a {
        line-height: $upper-nav-height;
        color: #FFF;
        font-size: .8em;
        font-weight: bolder;
        svg {
          margin-right: 5px;
          font-size: 1em;
        }
      }
    }
  }
}

.lower-nav {
  position: absolute;
  top:$upper-nav-height;
  right: 0;
  width: 100%;
  z-index: 99;
  background: transparent;
  height: $lower-nav-height;

  .nav-icon {
    margin: 25px;
    width: 60px;
    float: right;
    z-index: 999;
    background: $primary-color;
    padding: 5px 10px;
    position: relative;
    display: none;
  }

  .logo {
    margin-top: $lower-nav-height * .2;
    height: $lower-nav-height * .6;
    margin-left: 50px;
  }
  
  .nav-icon:after, 
  .nav-icon:before, 
  .nav-icon div {
    background-color: #FFF;
    border-radius: 3px;
    content: '';
    display: block;
    height: 5px;
    margin: 7px 0;
    transition: all .2s ease-in-out;
  }

 

  ul {
    font-family: $body-font-family;
    font-weight: bolder;
    list-style: none;
    padding: (($lower-nav-height - 50) / 2) 50px;
    margin: 0;
    float: right;

    li {
      display: inline-block;
      a {
        padding: 0 12px;
        line-height: 50px;
        color: #FFF;
        transition: .5s color ease;
        display: inline-block;

        &:hover {
          color: $secondary-color;
        }
      }

      &.cta {
        a {
          background: $secondary-color;
          padding: 0 50px;
          border-radius: 50px;
          color: #222;
          transition: .5s all ease;

          &:hover {
            background: darken($secondary-color, 10%);
          }
        }
      }
    }
  }
}

footer {
  padding: 50px 0 30px;
  position: relative;
  display: block;
  background: darken($primary-color, 10%);

  p {
    color: #FFF;
    font-weight: bold;
    text-align: center;
    line-height: 1.6em;
    margin: 0;
    padding: 0;
    font-size: 1.1em;

    b {
      font-size: 1.2em;
      font-family: $header-font-family;
    }

    a {
      color: #FFF;
      text-decoration: underline;

      &:hover {
        color: $secondary-color;
      }
    }
  }
  
}

@include breakpoint (large down) {
  .upper-nav {
    display: none;
  }

  .lower-nav {
    position: fixed;
    top: 0;
    background: $primary-color;

    .logo {
      margin-top: $lower-nav-height * .3;
      height: $lower-nav-height * .4;
      margin-left: 30px;
    }

    .nav-icon {
      pointer-events: all;
      display: block;
    }

    .nav-icon {
      margin: 30px 20px 0 0;
      width: 60px;
    }

    ul {
      pointer-events: none;
      padding: 0;
      background: #FFF;
      margin: 0;
      width: 100vw;
      position: fixed;
      top: $lower-nav-height;
      transform: translateX(100vw);
      transition: .5s all ease-out;
      z-index: 1;
      border-bottom: 2px solid $secondary-color;
      li {
        width: 100%;
        padding: 10px 0;
        border-bottom: 2px solid darken(#FFF, 10%);
        a {
          width: 100%;
          text-align: center;
          color: $primary-color;
        }

        &.cta {
          padding: 0;
          border-bottom: none;
          a {
            border-radius: 0;
            background: $primary-color;
            padding: 10px 0;
            color: #FFF;
          }
        }
      }
    }

    &.open {
      ul {
        transform: translateX(0);
        pointer-events: all;
      }

      .nav-icon:before {
        transform: translateY(12px) rotate(135deg);
      }
      
      .nav-icon:after {
        transform: translateY(-12px) rotate(-135deg);
      }
      
      .nav-icon div {
        transform: scale(0);
      }
    }
  }

  footer {
    p {
      font-size: .9em;
    }
  }
}